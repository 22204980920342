@keyframes slideIn {
  0% {
    transform: translateX(100%) scale(0.95);
    opacity: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  50% {
    transform: translateX(-5%) scale(1.03);
    opacity: 1;
    box-shadow: -10px 0 20px rgba(0, 0, 0, 0.2);
  }
  70% {
    transform: translateX(3%) scale(0.98);
    box-shadow: -8px 0 16px rgba(0, 0, 0, 0.15);
  }
  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
    box-shadow: -4px 0 12px rgba(0, 0, 0, 0.1);
  }
}

.sider {
  background: #fff;
  overflow: auto;
  border-right: 1px solid #f0f0f0;
  animation: slideIn 0.8s cubic-bezier(0.42, 0, 0.58, 1) forwards;
}

.inner-sider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fafafa;
}
