@import "assets/styles/mixins.scss";

.footer {
  background: $gray-border;
  padding: rem(40) rem(30) rem(30);
  margin: rem(0) rem(30) 0;
  border-radius: 5px 5px 0 0;

  @media (max-width: $sm-max-width) {
    margin: rem(-25) 0 0;
  }

  .copyright {
    @media (max-width: $xs-max-width) {
      margin-top: rem(20);
      float: none;
    }

    img {
      float: left;
      width: 30px;
      position: absolute;
      top: 6px;
    }
    span {
      display: block;
      margin-left: 50px;
    }
  }
}
