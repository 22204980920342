@import 'assets/styles/mixins.scss';

.topbar {
  background: $white;
  padding: 0 rem(20);
  min-height: 64px;
  height: 64px;
  border-bottom: 1px solid $gray-border;
  color: $text;
}
